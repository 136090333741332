/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
// import React from "react"
import { Flex, Box } from "theme-ui";
import { IconContext } from "react-icons";
import { AiFillBehanceSquare, AiFillLinkedin } from "react-icons/ai";

const Header = ({ author }) => (
  <header sx={{gridColumnStart: 'span 8'}}
  //sx={{position: 'fixed', width:'100%', zIndex:'10', backgroundColor:'white', overflow:'hidden'}}
  >
    <Flex alignItems='bottom'>
      <Link
        sx=
        {{
          fontFamily: 'title',
          fontSize: 5,
          letterSpacing: '-1px',
          display: ['none', 'none', 'block'],
          whiteSpace: 'nowrap'
        }}
        to={'/'}> {author}
      </Link>
      <Link
        sx=
        {{
          fontFamily: 'title',
          fontSize: 5,
          letterSpacing: '-1px',
          display: ['none', 'block', 'none'],
          whiteSpace: 'nowrap'
        }}
        to={'/'}>CMorejon</Link>
      <Link
        sx=
        {{
          fontFamily: 'title',
          fontSize: 5,
          letterSpacing: '-1px',
          display: ['block', 'none', 'none'],
          whiteSpace: 'nowrap'
        }}
        to={'/'}>CM
      </Link>
      <Box sx={{alignSelf:'center', ml:'1', mt:'2'}}>
        <IconContext.Provider
          value={{ size: "1.75em", }}>
          <a
            href='https://www.behance.net/mmorej20'
            target='_blank'
            rel="noreferrer noopener"
            id= "behanceIcon" 
          >
            <AiFillBehanceSquare />
          </a>
          <a
            href='https://www.linkedin.com/in/cristinamorejon/'
            target='_blank'
            rel="noreferrer noopener"
            id= "linkedinIcon" 

          >
            <AiFillLinkedin />
          </a>
        </IconContext.Provider>
      </Box>
      <Box m='auto' />
      <Box>
        <Link activeClassName="active"
          sx={{
            borderBottom: '2px transparent',
            ':hover': {
              borderBottom: '2px solid',
              transition: 'border-color 0.3s ease-in',
            },
            '.active': {
              borderBottom: '3px solid',
              ':hover': {
                borderBottom: '3px solid',
              },
            },
            lineHeight: 3,
            fontSize: [1,2],
            pb: 1
          }}
          to={'/'} variant='nav'>Portfolio</Link>
      </Box>
      <Box mx={[2,3]}>
        <Link activeClassName="active"
          sx={{
            borderBottom: '2px transparent',
            ':hover': {
              borderBottom: '2px solid',
              transition: 'border-color 0.3s ease-in',
            },
            '.active': {
              borderBottom: '3px solid',
              ':hover': {
                borderBottom: '3px solid',
              },
            },
            lineHeight: 3,
            fontSize: [1,2],
            pb: 1
          }}
          to={'/thoughts'} variant='nav'>Thoughts</Link>
      </Box>
      <Box>
        <Link activeClassName="active"
          sx=
          {{
            borderBottom: '2px transparent',
            ':hover': {
              borderBottom: '2px solid',
              transition: 'border-color 0.3s ease-in',
            },
            '.active': {
              borderBottom: '3px solid',
              ':hover': {
                borderBottom: '3px solid',
              },
            },
            lineHeight: 3,
            fontSize: [1,2],
            pb: 1,
          }}
          to={'/about'} variant='nav'>About</Link>
      </Box>
    </Flex>
  </header>
)


export default Header
