/** @jsx jsx */
// import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { jsx, Box } from "theme-ui";


const Footer = ({ author }) => (
  <footer sx={{gridColumnStart: 'span 8'}}>
    <Box pt={3}>
    © {new Date().getFullYear()}
    {` `}
    <Link to="/about">{author}</Link>
    </Box>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
